import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";

export interface TariffInfo {
  id: number;
  title: string;
  description: string;
  price: number;
  article: string;
  discount_6m: number;
  discount_12m: number;
  count_projects: number;
  count_applications: number;
  count_leads: number;
}
export interface UserTariffInfo{
  id: number,
  title: string,
  tariff:TariffInfo,
  transaction: number;
  end_date: string;
  user: number;
  active: boolean;
  count_project: number,
  count_leads: number;
}

export const tariffsApi = createApi({
  reducerPath: "tariffsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["TariffInfo", "TariffUserInfo"],
  endpoints: (builder) => ({
    getTariffs: builder.query<TariffInfo[], void>({
      query: () => ({ url: "tariffs/", method: "GET" }),
      providesTags: [{ type: "TariffInfo", id: "LIST" }],
    }),
    getTariffInfo: builder.query<UserTariffInfo, void>({
      query: () => ({ url: "tariffinfo/", method: "GET" }),
      providesTags: [{ type: "TariffUserInfo", id: "LIST" }],
    })
  }),
});

export const { useGetTariffsQuery, useGetTariffInfoQuery } = tariffsApi;
