import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { QuestionTypeValue } from "./questions";

export enum TypeLeadInfo {
  Form = 0,
  SocialNetwork = 1,
}

interface LeadInfoData {}

export interface LeadInfo {
  id: number;
  data: LeadInfoData;
  type: TypeLeadInfo;
  quiz: number;
  session: number;
  quiz_in_progress: number;
  datetime: string;
  tariff: number | null;
}

export interface LeadAnswer {
  data: LeadAnswerData;
  datetime: string;
}
export interface LeadAnswerData {
  answers: AnswerTypeData;
  question: string;
  question_type: QuestionTypeValue;
}

export type OptionsTypeAnswer = string[];
export type TextTypeAnswer = string;
export type RateTypeAnswer = { value: number };
export type DateTimeTypeAnswer = {
  date: { to: string; from: string };
  time: { to: string; from: string };
};
export type SliderTypeAnswer = { value: number[] };

export type AnswerTypeData =
  | OptionsTypeAnswer
  | TextTypeAnswer
  | RateTypeAnswer
  | DateTimeTypeAnswer
  | SliderTypeAnswer;

export const leadsApi = createApi({
  reducerPath: "leadsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Leads", "Answers"],
  endpoints: (builder) => ({
    getLeads: builder.query<LeadInfo[], void>({
      query: () => ({ url: "leadslist/", method: "GET" }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Leads", id } as const)),
              { type: "Leads", id: "LIST" },
            ]
          : [{ type: "Leads", id: "LIST" }],
    }),
    getAnswersById: builder.query<LeadAnswer[], number>({
      query: (id) => ({ url: `lead_answers/${id}/`, method: "GET" }),
      providesTags: (result, error, id) => [{ type: "Answers", id }],
    }),
    updateLeadTariff: builder.mutation<any, {lead_id: number}>(
      {
        query: (data) => ({ url: "update-lead-tariff/", method: "POST", data }),
        invalidatesTags: [{ type: "Leads", id: "LIST" }],
      }
    ),
  }),
});

export const { useGetLeadsQuery, useGetAnswersByIdQuery, useUpdateLeadTariffMutation } = leadsApi;
